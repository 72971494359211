import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/react";

const Layout = () => {
  const bg = useColorModeValue("gray.200", "gray.800");

  return (
    <Flex direction="column" minHeight="100vh" bg={bg}>
      <NavBar />
      <Box flex="1" p={8}>
        <Flex p={2} justifyContent="center" alignItems="center" >
          <Outlet />
        </Flex>
      </Box>
      <Footer />
    </Flex>
  );
};

export default Layout;
