import { Box, Spinner as ChakraSpinner } from "@chakra-ui/react";

const Spinner = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minH="300px">
      <ChakraSpinner size="xl" />
    </Box>
  )
};

export default Spinner;
