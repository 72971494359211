import { HStack, Switch, useColorMode, Icon } from "@chakra-ui/react";
import { FaMoon, FaSun } from 'react-icons/fa';

const ColorModeSwitch = () => {
  const { toggleColorMode, colorMode } = useColorMode();

  return (
    <HStack>
      <Switch
        isChecked={colorMode === "dark"}
        onChange={toggleColorMode}
      />
      {colorMode === 'dark' ? <Icon as={FaMoon} /> : <Icon as={FaSun} />}
    </HStack>
  );
};

export default ColorModeSwitch;
