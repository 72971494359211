import React, { useState } from "react";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  GithubAuthProvider,
} from "firebase/auth";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { FaGoogle, FaGithub } from "react-icons/fa";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const formBackground = useColorModeValue("gray.200", "gray.700");

  const googleProvider = new GoogleAuthProvider();
  const githubProvider = new GithubAuthProvider();

  const signIn = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");
    } catch (error) {
      alert((error as Error).message);
    }
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate("/dashboard");
    } catch (error) {
      alert((error as Error).message);
    }
  };

  const signInWithGithub = async () => {
    try {
      await signInWithPopup(auth, githubProvider);
      navigate("/dashboard");
    } catch (error) {
      alert((error as Error).message);
    }
  };

  return (
    <Box
      p={5}
      w={["90%", "80%", "70%", "60%", "400px"]}
      mx="auto"
      mt="100px"
      boxShadow="md"
      rounded="md"
      bg={formBackground}
    >
      <VStack as="form" onSubmit={signIn} spacing={5}>
        <Heading mb={6}>Sign in</Heading>
        <FormControl id="email" isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Enter your email"
            autoComplete="email"
          />
        </FormControl>
        <FormControl id="password" isRequired>
          <FormLabel>Password</FormLabel>
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Enter your password"
            autoComplete="current-password"
          />
        </FormControl>
        <Button colorScheme="teal" type="submit">
          Sign In
        </Button>
        <HStack>
          <Button
            leftIcon={<FaGoogle />}
            colorScheme="blue"
            onClick={signInWithGoogle}
            isDisabled={true}
          >
            Google
          </Button>
          <Button
            leftIcon={<FaGithub />}
            colorScheme="purple"
            onClick={signInWithGithub}
            isDisabled={true}
          >
            Github
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default SignIn;
