import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import Layout from "./pages/Layout";
import { SignUp } from "./pages/SignUpPage";
import SignIn from "./pages/SignInPage";
import DashboardPage from "./pages/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import SettingsPage from "./pages/SettingsPage";
import OrganizationCreate from "./pages/OrganizationCreate";
import Organization from "./pages/Organization";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <HomePage /> },
      { path: "/signin", element: <SignIn /> },
      { path: "/signup", element: <SignUp /> },
      {
        path: "/dashboard",
        element: (
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/settings",
        element: (
          <ProtectedRoute>
            <SettingsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/settings/organizations",
        element: (
          <ProtectedRoute>
            <OrganizationCreate />
          </ProtectedRoute>
        ),
      },
      {
        path: "/settings/organizations/:orgId",
        element: (
          <ProtectedRoute>
            <Organization />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

export default router;
