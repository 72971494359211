import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/AuthContext";
import { Box, Button, Input, Heading, VStack } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { createOrganization } from "../hooks/useOrganizations";

const OrganizationCreate: React.FC = () => {
  const navigate = useNavigate();
  const [orgName, setOrgName] = useState("");
  const { user } = useContext(AuthContext);

  const mutation = useMutation((args: { orgName: string, uid: string }) => createOrganization(args.orgName, args.uid), {
    onSuccess: () => {
      navigate('/settings/');
    },
    onError: (error: any) => {
      console.error('Error creating organization:', error);
    }
  });

  const handleCreateOrganization = () => {
    if (orgName && user) {
      mutation.mutate({ orgName, uid: user.uid });
    }
  };

  return (
    <Box>
      <VStack spacing={4} align="stretch">
        <Heading>Create a new Organization</Heading>
        <Input
          type="text"
          placeholder="Organization Name"
          value={orgName}
          onChange={(e) => setOrgName(e.target.value)}
        />
        <Button colorScheme="blue" onClick={handleCreateOrganization} isLoading={mutation?.isLoading}>
          Create Organization
        </Button>
      </VStack>
    </Box>
  );
};

export default OrganizationCreate;
