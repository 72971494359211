import {
  Box,
  Heading,
  LinkBox,
  LinkOverlay,
  List,
} from "@chakra-ui/react";
import { collection, getFirestore } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { Link as RouterLink } from "react-router-dom";
import { firebaseApp } from "../firebase";
import Spinner from "./Spinner";

const OrganisationsList: React.FC = () => {
  const [value, loading] = useCollection(
    collection(getFirestore(firebaseApp), "organizations")
  );

  return (
    <Box>
      {loading ? <Spinner />
        : value ? (
          <List spacing={5}>
            {value.docs.map((doc) => (
              <LinkBox
                as="article"
                key={doc.id}
                p={5}
                borderWidth={1}
                rounded="md"
                _hover={{ shadow: "md" }}
              >
                <Heading size="md">
                  <LinkOverlay
                    as={RouterLink}
                    to={`/settings/organizations/${doc.id}`}
                  >
                    {doc.data().name}
                  </LinkOverlay>
                </Heading>
              </LinkBox>
            ))}
          </List>
        ) : (
          <Heading size="md" my={6}>
            You are not part of any organisation.
          </Heading>
        )}
    </Box>
  );
};

export default OrganisationsList;
