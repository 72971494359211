import { Flex, Spinner } from '@chakra-ui/react';
import { User } from "firebase/auth";
import { ReactNode, createContext } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from "../firebase";

interface UserState {
  user: User | null | undefined;
  loading: boolean;
  error: Error | undefined;
}

interface Props {
  children: ReactNode;
}

export const AuthContext = createContext<UserState>({
  user: null,
  loading: false,
  error: undefined,
});

export const AuthProvider = ({ children }: Props) => {
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return (
      <Flex justify="center" align="center" minH="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <AuthContext.Provider value={{ user, loading, error }}>
      {children}
    </AuthContext.Provider>
  );
};
