import { Table, Thead, Tr, Th, Tbody, Td, Badge, Text } from "@chakra-ui/react";
import { Alert } from "../entities/Alert";
import Spinner from "./Spinner";

interface AlertListProps {
  alerts: Alert[];
  isLoading: boolean;
}

const AlertList: React.FC<AlertListProps> = ({ alerts, isLoading }) => {
  if (alerts && !isLoading) {
    return (
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Type</Th>
            <Th>Title</Th>
            <Th>Message</Th>
            <Th>Timestamp</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {alerts.map((alert) => (
            <Tr
              key={alert.id}
              opacity={alert.status === "ACTIVE" ? 1 : 0.5}
            >
              <Td>
                <Badge variant="solid" colorScheme="red">
                  {alert.severity}
                </Badge>
              </Td>
              <Td>{alert.title}</Td>
              <Td>{alert.description}</Td>
              <Td>{alert.createdAt.toDateString()}</Td>
              <Td>{alert.status}</Td>
              <Td>{alert.assignedTo}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  } else if (isLoading) {
    return <Spinner />;
  } else {
    return <Text>No alerts found</Text>;
  }
}

export default AlertList;
