import {
  Box,
  Button,
  Flex,
  HStack,
  Spacer,
  Text,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
} from "@chakra-ui/react";
import { signOut } from "firebase/auth";
import { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";
import { AuthContext } from "../auth/AuthContext";
import { auth } from "../firebase";
import ColorModeSwitch from "./ColorModeSwitch";
import UserMenu from "./UserMenu";

const NavBar = () => {
  const { user } = useContext(AuthContext);

  const handleLogout = useCallback(async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  }, []);

  const isMobileNav = useBreakpointValue({ base: true, md: false });

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <HStack width="full" justifyContent="space-between" padding="10px">
        <Box>
          <Link to={user ? "/dashboard" : "/"}>
            <Text fontSize="2xl" fontWeight="bold" color="teal.300">
              oh sheit!
            </Text>
          </Link>
        </Box>
        <Spacer />
        {user ? (
          <HStack>
            <UserMenu user={user} onLogout={handleLogout} />
          </HStack>
        ) : isMobileNav ? (
          <HStack>
            <ColorModeSwitch />
          <Menu >
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              size="md"
              variant="outline"
            />
            <MenuList>
              <MenuItem as={Link} to="/signup">
                Get started
              </MenuItem>
              <MenuItem as={Link} to="/signin">
                Sign in
              </MenuItem>
            </MenuList>
          </Menu>
          </HStack>
        ) : (
          <HStack>
            <ColorModeSwitch />
            <Link to="/signup">
              <Button colorScheme="teal" variant="outline">
                Get Started
              </Button>
            </Link>
            <Link to="/signin">
              <Button colorScheme="teal">Sign In</Button>
            </Link>
          </HStack>
        )}
      </HStack>
    </Flex>
  );
};

export default NavBar;
