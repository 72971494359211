import { Timestamp, addDoc, collection, doc, setDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";

export const createOrganization = async (orgName: string, userId: string) => {
  const orgData = {
    name: orgName,
    updatedAt: Timestamp.now(),
    createdAt: Timestamp.now(),
  };

  const orgDocRef = await addDoc(collection(db, 'organizations'), orgData);

  const userRef = doc(db, 'Users', userId);
  await setDoc(userRef, {
    orgs: arrayUnion(orgDocRef.id)
  }, { merge: true });

  return orgDocRef.id;
};

