import { Box, Text } from "@chakra-ui/react";
import Spinner from "../components/Spinner";
import AlertList from "../components/AlertList";
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { collection, query, where, getFirestore } from "@firebase/firestore";
import { firebaseApp } from "../firebase";
import { Alert } from "../entities/Alert";

const DashboardPage = () => {
  const orgId = 'DJaKzNky298yGs4fXIgc';  // getOrgIdForUser(user);
  const alertsRef = collection(getFirestore(firebaseApp), 'alerts');
  const [alertsData, isLoading, error] = useCollectionData(
    query(alertsRef, where("organizationId", "==", orgId)),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );

  const alerts: Alert[] = alertsData?.map((alert) => ({
    id: alert.id,
    status: alert.status,
    severity: alert.severity,
    title: alert.title,
    description: alert.description,
    createdAt: alert.createdAt?.toDate(),
    updatedAt: alert.updatedAt?.toDate(),
    closedAt: alert.closedAt?.toDate() || null,
    assignedTo: alert.assignedTo,
    organizationId: alert.organizationId,
  })) || [];

  if (isLoading) return <Spinner />;

  return (
    <Box>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        Alert List
      </Text>
      <AlertList alerts={alerts} isLoading={isLoading} />
    </Box>
  );
};

export default DashboardPage;
