import {
  Box,
  Heading,
  Text,
  Input,
  Button,
  useColorModeValue,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { FaGithub, FaGoogle } from "react-icons/fa";
import { HStack } from "@chakra-ui/react";

export const SignUp = () => {
  const formBackground = useColorModeValue("gray.200", "gray.700");

  return (
    <Box
      p={5}
      w={["90%", "80%", "70%", "60%", "400px"]}
      mx="auto"
      mt="100px"
      boxShadow="md"
      rounded="md"
      bg={formBackground}
    >
      <VStack spacing={3}>
        <Heading mb={6}>Sign Up</Heading>
        <Text>
          This app is currently in an invite-only phase while we make sure it
          works as expected. If you'd like to be part of this exciting journey,
          please email support@ohsheit.com to get in touch.
        </Text>
        <Stack spacing={3} marginTop={4}>
          <Input placeholder="Email" isDisabled />
          <Input placeholder="Password" isDisabled />
          <Input placeholder="Confirm Password" isDisabled />
        </Stack>
        <Button colorScheme="teal" type="submit" isDisabled>
          Get started
        </Button>
        <HStack>
          <Button
            leftIcon={<FaGoogle />}
            colorScheme="blue"
            onClick={() => {}}
            isDisabled
          >
            Google
          </Button>
          <Button
            leftIcon={<FaGithub />}
            colorScheme="purple"
            onClick={() => {}}
            isDisabled
          >
            Github
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};
