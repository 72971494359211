import { Button, Center, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { useContext } from "react";
import { TbFaceIdError } from "react-icons/tb";
import { Link, isRouteErrorResponse, useRouteError } from "react-router-dom";
import { AuthContext } from "../auth/AuthContext";
import NavBar from "../components/NavBar";

const ErrorPage = () => {
  const error = useRouteError();
  const { user } = useContext(AuthContext)!;

  return (
    <>
      <NavBar />
      <Center minHeight="80vh">
        <VStack spacing={2} align="center">
          <Icon boxSize="200px" objectFit="cover" as={TbFaceIdError} />
          <Heading>Oops</Heading>
          <Text textAlign="center" fontSize="xl">
            {isRouteErrorResponse(error)
              ? "It seems like the page you're looking for doesn't exist."
              : "Oops, an unexpected error occurred."}
          </Text>
          <Button colorScheme="blue" as={Link} to={user ? "/dashboard" : "/"}>
            Go Back Home
          </Button>
        </VStack>
      </Center>
    </>
  );
};

export default ErrorPage;
