import { Box, Heading, VStack, Text } from "@chakra-ui/react";
import { DocumentReference, doc, getFirestore } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { firebaseApp } from "../firebase";

const Organization: React.FC = () => {
  const { orgId } = useParams();
  let docRef: DocumentReference | null = null;

  if (orgId) docRef = doc(getFirestore(firebaseApp), "organizations", orgId);

  const [value, loading, error] = useDocument(docRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  if (!orgId) return <div>Error: Organisation ID not provided</div>;

  const org = value?.data();

  return (
    <Box maxW="container.md" mx="auto" p={4}>
      <Heading mb={4}>Organisation Settings</Heading>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading ? (
        <span>Document: Loading...</span>
      ) : org ? (
        <VStack spacing={4} align="start">
          <Text><strong>Name:</strong> {org.name}</Text>
          <Text><strong>Admins:</strong> {org.admins}</Text>
          <Text><strong>Teams:</strong> {org.teams}</Text>
          <Text><strong>Alerts:</strong> {org.alerts}</Text>
          <Text><strong>Integrations:</strong> {org.integrations}</Text>
          <Text><strong>Updated At:</strong> {org.updatedAt}</Text>
        </VStack>
      ) : (
        <span>Document not found</span>
      )}
    </Box>
  );
};

export default Organization;
