import { extendTheme, ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme({ 
  config,
  colors: {
    gray: {
      50: '#f7fafc',
      100: '#edf2f7',
      200: '#e2e8f0',
      300: '#cbd5e0',
      400: '#a0aec0',
      500: '#718096',
      600: '#4a5568',
      700: '#2d3748',
      800: '#1a202c',
      900: '#171923'
    },
    teal: {
      50: '#edfafa',
      100: '#b2f5ea',
      200: '#80edc2',
      300: '#40c8a4',
      400: '#2a7f7f',
      500: '#285e61',
      600: '#234e52',
      700: '#1d4044',
      800: '#173d3f',
      900: '#0f2526'
    }
  }
});

export default theme;  
