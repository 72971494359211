import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaBell, FaBuilding, FaTools, FaUser } from "react-icons/fa";
import { Link as RouterLink } from 'react-router-dom';
import OrganizationsList from "../components/OrganizationList";

const ProfileSettings = () => <div>Profile Settings</div>;
const OrgSettings = () => {
  return (
    <Box>
      <Button 
        as={RouterLink} 
        to='/settings/organizations/' 
        colorScheme="blue" 
        mb={5}
      >
        Create Organization
      </Button>
      <OrganizationsList />
    </Box>
  )
}

const NotificationSettings = () => <div>Notifications Settings</div>;
const IntegrationSettings = () => <div>Integrations Settings</div>;

interface SettingsOption {
  label: string;
  component: JSX.Element;
  icon: any;
}

const SettingsOptions: SettingsOption[] = [
  { label: "Profile", component: <ProfileSettings />, icon: FaUser },
  { label: "Organizations", component: <OrgSettings />, icon: FaBuilding },
  { label: "Notifications", component: <NotificationSettings />, icon: FaBell },
  { label: "Integrations", component: <IntegrationSettings />, icon: FaTools },
];

const SettingsPage = () => {
  const [activeSetting, setActiveSetting] = useState(
    SettingsOptions[0].component
  );
  const [activeLabel, setActiveLabel] = useState(SettingsOptions[0].label);

  const handleClick = (component: JSX.Element, label: string) => {
    setActiveSetting(component);
    setActiveLabel(label);
  };

  const activeBg = useColorModeValue("gray.200", "gray.700");
  const hoverBg = useColorModeValue("gray.100", "gray.600");

  const SidebarContent = (
    <List spacing={5} styleType="none">
      {SettingsOptions.map(({ label, component, icon: Icon }) => (
        <ListItem
          key={label}
          display="flex"
          alignItems="center"
          onClick={() => handleClick(component, label)}
          bg={activeLabel === label ? activeBg : ""}
          _hover={{ bg: hoverBg }}
          cursor="pointer"
          p={2}
          rounded="md"
        >
          <Icon />
          <Box ml={2}>{label}</Box>
        </ListItem>
      ))}
    </List>
  );

  const DropdownContent = (
    <Menu>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
        {activeLabel}
      </MenuButton>

      <MenuList>
        {SettingsOptions.map(({ label, component, icon: Icon }) => (
          <MenuItem
            key={label}
            icon={<Icon />}
            onClick={() => handleClick(component, label)}
          >
            {label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );

  return (
    <Flex direction={["column", "row"]} justify="center" gap={5} p={5}>
      <Box
        flexShrink={0}
        display={{ base: "none", md: "block" }}
        w="250px"
        borderRight="1px"
        borderColor="gray.300"
      >
        <Heading as="h3" size="lg" mb={5}>
          Settings
        </Heading>
        {SidebarContent}
      </Box>
      <Box display={{ base: "block", md: "none" }} mb={4}>
        {DropdownContent}
      </Box>
      <Box flex={1} maxW="800px">
        <Heading as="h2" size="md" mb={5}>
          {activeLabel}
        </Heading>
        {activeSetting}
      </Box>
    </Flex>
  );
};

export default SettingsPage;
