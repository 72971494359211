import {
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { User } from "firebase/auth";
import { Link } from "react-router-dom";
import ColorModeSwitch from "./ColorModeSwitch";

interface UserMenuProps {
  user: User;
  onLogout: () => void;
}

const UserMenu = ({ user, onLogout }: UserMenuProps) => (
  <Menu>
    <MenuButton
      as={Button}
      rounded="full"
      variant="link"
      cursor="pointer"
      p={2}
    >
      {user.photoURL ? (
        <Avatar size="sm" name={user.email || ""} src={user.photoURL} />
      ) : (
        <Avatar size="sm" name={user?.email || ""} />
      )}
    </MenuButton>
    <MenuList>
      <MenuItem as={Link} to="/dashboard">
        Dashboard
      </MenuItem>
      <MenuItem as={Link} to="/settings">
        Settings
      </MenuItem>
      <MenuItem>
        <ColorModeSwitch />
      </MenuItem>
      <MenuItem onClick={onLogout}>Logout</MenuItem>
    </MenuList>
  </Menu>
);

export default UserMenu;
