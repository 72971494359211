import {
  Divider,
  Flex,
  Heading,
  Icon,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { AiFillDollarCircle } from "react-icons/ai";
import { FaRegHandPeace } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/AuthContext";

const HomePage = () => {
  const { user } = useContext(AuthContext)!;
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (user) navigate("/dashboard");
  }, [user, navigate, toast]);

  return (
    <Flex
      flexDirection="column"
      padding={3}
      justifyContent="center"
      flex="1"
      textAlign="center"
    >
      <VStack spacing={9}>
        <Heading>Just On-Call</Heading>
        <Text fontSize="xl">
          No BS, just on-call
        </Text>
        <Divider />
        <Icon boxSize={8} as={FaRegHandPeace} />
        <Heading size="md" mt={4}>
          Simple
        </Heading>
        <Text mt={2}>
          So easy, even a caveman could do it.
          <br />
          Even if tech isn't your thing, we've made managing your on-call
          schedules and alerts easy peasy.
        </Text>
        <Divider />
        <Icon boxSize={8} as={AiFillDollarCircle} />
        <Heading size="md" mt={4}>
          Affordable
        </Heading>
        <Text mt={2}>
          Let's be honest, we're essentially a refined alert router. So, we
          won't burden you with needless costs. Simply a fair price for a tool
          that does its job well.
        </Text>
        <Divider />
        <Icon boxSize={8} as={GoVerified} />
        <Heading size="md" mt={4}>
          Trusted by ...
        </Heading>
        <Text mt={2}>
          Currently, our customer base consists of... well, a bunch of zeros. But
          hey, zero is also a number, right? 😄
        </Text>
      </VStack>
    </Flex>
  );
};

export default HomePage;
