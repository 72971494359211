import { Box, Text } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box p="4" textAlign="center">
      <Text>© 2023 oh sheit!</Text>
    </Box>
  );
};

export default Footer;
