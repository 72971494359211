import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyDb2esbpIeUiEH6M0WlQCcHtiCV8kHChb0",
  authDomain: "ohsheit-7ac56.firebaseapp.com",
  projectId: "ohsheit-7ac56",
  storageBucket: "ohsheit-7ac56.appspot.com",
  messagingSenderId: "155838653918",
  appId: "1:155838653918:web:360f24c8526d71a9ba294f",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
